import { useState, useEffect, useCallback, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Divider from '@mui/material/Divider';

import { useLoading } from '../../context/loading-context';
import { useErrorHandler } from '../../context/error-handler-context';
import axios from '../../utils/axios';

import Filter from '../Sections/Filter';
import Chart from '../Sections/Chart';
import CurrentFinancing from '../Sections/CurrentFinancing';
import PaymentInfo from '../Sections/PaymentInfo';
import QrCode from '../Sections/QrCode';
import Button from '../UI/Button';

import { formatMoney } from '../../utils/format';

import styles from './Overview.module.scss';
import ApplicationProgress from './Application/ApplicationProgress';
import {
  ExternalUserDto,
  ExternalUserDtoConnectedPartnerDto,
  IsoCurrencyCode,
  LoanDto,
} from '../../types/api';
import { RobotImage } from '../UI/RobotImage';
import { useAppSelector } from '../../selectors/selectors';

interface IOverviewProps {
  customerId: string;
  currentUser: ExternalUserDto;
  partner: ExternalUserDtoConnectedPartnerDto;
  string?: any;
  loanActive: Array<LoanDto>;
  loanHistory: any;
  applicationData: any;
  changePartner: (partner: ExternalUserDtoConnectedPartnerDto) => void;
  changeBranch?: () => void;
}

const Overview: FC<IOverviewProps> = ({
  applicationData,
  currentUser,
  loanActive,
  loanHistory,
  partner,
  customerId,
  changePartner,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [insights, setInsights] = useState<any>(null);
  const { offer } = useAppSelector((state) => state.offer);
  const { defaultCurrency } = useAppSelector((state) => state.currentCustomer);

  const { setLoading } = useLoading();
  const { setError } = useErrorHandler();

  const isRep2 =
    applicationData &&
    applicationData.application.rep2Id &&
    applicationData.application.rep2Id === currentUser.userId;

  const isRep2ActionRequired =
    isRep2 &&
    ['NEW', 'SUBMITTED', 'CHECKING', 'SENT_FOR_APPROVING'].includes(
      applicationData.application.state
    ) &&
    !applicationData.rep2KycValid;

  let prevRevenues = 0;
  let nextRevenues = 0;
  let prevAverage = 0;
  let nextAverage = 0;

  const fixedInsights = {
    previous: insights ? insights.previous : {},
    next: insights ? insights.next : {},
  };

  if (insights) {
    if (insights.previous.dailyRevenues) {
      insights.previous.dailyRevenues.forEach((e: any) => {
        prevRevenues += +e.revenue;
      });

      prevAverage = prevRevenues / insights.previous.dailyRevenues.length;
    }

    if (insights.next.dailyRevenues) {
      insights.next.dailyRevenues.forEach((e: any) => {
        nextRevenues += +e.revenue;
      });

      nextAverage = nextRevenues / insights.next.dailyRevenues.length;
    }
  }

  const getInsights = useCallback(() => {
    setLoading(true);
    axios
      .get(
        !partner.tenantId
          ? `insights/partners/${partner.code}/merchants/${partner.merchantId}/revenues`
          : `insights/partners/${partner.code}/merchants/${partner.merchantId}_${partner.tenantId}/revenues`
      )
      .then((res) => {
        setLoading(false);
        setInsights(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }, [partner, setError, setLoading]);

  useEffect(() => {
    if (partner) {
      getInsights();
    }
  }, [getInsights, partner, setError, setLoading, customerId]);

  if (
    !loanActive &&
    loanHistory &&
    // !filteredInsights.previous.dailyRevenues &&
    // !filteredInsights.next.dailyRevenues &&
    !offer &&
    !applicationData
  ) {
    return (
      <div className={styles['finished-wrapper']}>
        <RobotImage />

        <div className={styles['info-wrapper']}>
          <div className="gradient info">
            <p>
              <Trans
                i18nKey="overview-finished-financing"
                components={[<br />, <span></span>]}
              ></Trans>
            </p>
          </div>

          <Button
            onClick={() => navigate(`/${customerId}/connections`)}
            className="gradient dialog"
          >
            <span>{t('overview-new-connection')}</span>
          </Button>
        </div>
      </div>
    );
  } else {
    const visibleApplicationBanner = !loanActive && !isRep2 && applicationData;
    const showInProgressBanner = !isRep2 && applicationData;
    const visibleApplicationRep2Banner = !loanActive && isRep2ActionRequired;
    const visibleOfferBanner =
      (offer && !applicationData) ||
      (offer && !offer?.customerWithActiveProduct);

    const offerCode = offer?.partnerCode;
    const merchantId = offer?.merchantId;

    return (
      <>
        {visibleOfferBanner ? (
          <div className={styles['offer-mobile']}>
            <Button
              className="overview-banner-mobile"
              type="button"
              onClick={() =>
                navigate(`/${customerId}/offer/${offerCode}/${merchantId}`)
              }
            >
              {t('overview-new-offer')}
            </Button>
            <span>{t('overview-new-offer-alt')}</span>
          </div>
        ) : null}

        {visibleApplicationBanner ? (
          <div className={styles['offer-mobile']}>
            {applicationData.application.inputState !== 'DONE' && (
              <>
                <Button
                  className="overview-banner-mobile"
                  type="button"
                  onClick={() => navigate(`/${customerId}/application`)}
                >
                  {t('overview-in-progress')}
                </Button>
                <span>{t('overview-in-progress-alt')}</span>
              </>
            )}

            {applicationData.application.inputState === 'DONE' && (
              <span>{t('overview-in-progress')}</span>
            )}

            <div className={styles.progress}>
              <ApplicationProgress
                className="overview"
                application={applicationData.application}
                customerId={customerId}
              />
            </div>
          </div>
        ) : null}

        {visibleApplicationRep2Banner ? (
          <div className={styles['offer-mobile']}>
            <Button
              className="overview-banner-mobile"
              type="button"
              onClick={() => navigate(`/${customerId}/application`)}
            >
              {t('overview-open-application')}
            </Button>
            <span>{t('overview-photo-id')}</span>
          </div>
        ) : null}

        {partner && insights ? (
          <Filter
            customerId={customerId}
            currentUser={currentUser}
            partner={partner}
            changePartner={changePartner}
          />
        ) : null}

        <div className={styles['revenue-wrapper']}>
          <div className={styles.revenue}>
            <div className={styles.period}>
              {t('overview-insights-prev-period')}
              <p className={styles.value}>
                {formatMoney(prevRevenues, defaultCurrency!, false)}
              </p>
            </div>
            <div className={styles.daily}>
              {t('overview-insights-average-sales')}
              <p className={styles.sub}>
                {t('overview-insights-in-prev-period')}
              </p>
              <p className={styles.value}>
                {formatMoney(prevAverage, defaultCurrency!, false)}
              </p>
            </div>
          </div>
          <div className={`${styles.revenue} ${styles.next}`}>
            <div className={styles.period}>
              {t('overview-insights-next-period')}
              <p className={styles.value}>
                {formatMoney(nextRevenues, defaultCurrency!, false)}
              </p>
            </div>
            <div className={styles.daily}>
              {t('overview-insights-average-sales')}
              <p className={styles.sub}>
                {t('overview-insights-in-next-period')}
              </p>
              <p className={styles.value}>
                {formatMoney(nextAverage, defaultCurrency!, false)}
              </p>
            </div>
          </div>
        </div>
        <Chart insights={fixedInsights} />

        {visibleOfferBanner ? (
          <div className={styles.offer}>
            <div className={styles['offer-body']}>
              {/* <RobotImage /> */}
              <div className={styles['body-info-wrapper']}>
                <h2>{t('overview-offer-waiting')}</h2>
                <div className={styles['body-info']}>
                  <span>{t('overview-offer-waiting-alt')}</span>
                  <Button
                    id="calc_open"
                    className="overview-banner"
                    type="button"
                    onClick={() =>
                      navigate(
                        `/${customerId}/offer/${offerCode}/${merchantId}`
                      )
                    }
                  >
                    {t('overview-open-calc')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {showInProgressBanner ? (
          <div className={styles.offer}>
            <div className={styles['offer-body']}>
              <RobotImage />
              <div className={styles['body-info-wrapper']}>
                <h2>
                  {applicationData.application.inputState === 'DONE'
                    ? t('overview-offer-done')
                    : t('overview-offer-continue')}
                </h2>
                <div className={styles['body-info']}>
                  <span>
                    {applicationData.application.inputState === 'DONE'
                      ? t('overview-offer-done-alt')
                      : t('overview-offer-continue-alt')}
                  </span>

                  {applicationData.application.inputState !== 'DONE' && (
                    <Button
                      className="overview-banner"
                      type="button"
                      onClick={() => navigate(`/${customerId}/application`)}
                    >
                      {t('overview-open-offer')}
                    </Button>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.progress}>
              <ApplicationProgress
                className="overview"
                application={applicationData.application}
                customerId={customerId}
              />
            </div>
          </div>
        ) : null}

        {loanActive && loanActive.length > 0
          ? loanActive.map((loan, index) => {
              return (
                <div key={loan.id}>
                  <div className={styles['financing-wrapper']}>
                    <div className={styles['current-financing-wrapper']}>
                      <h2>
                        {t('financing-active-financing', { index: index + 1 })}
                      </h2>
                      <CurrentFinancing customerId={customerId} loan={loan} />
                    </div>

                    {loan.paymentInstruction ? (
                      <div className={styles['financing-info-wrapper']}>
                        <PaymentInfo
                          paymentInstruction={loan.paymentInstruction}
                          currency={loan.currency as IsoCurrencyCode}
                        />
                        <QrCode
                          currency={loan?.paymentInstruction?.currency ?? ''}
                          qrCode={loan?.paymentInstruction?.qrCodeString ?? ''}
                        />
                      </div>
                    ) : null}
                  </div>
                  {index < loanActive.length - 1 ? (
                    <Divider sx={{ margin: '0 0.8rem 0 0.8rem' }} />
                  ) : null}
                </div>
              );
            })
          : null}

        {visibleApplicationRep2Banner ? (
          <div className={styles.offer}>
            <div className={styles['offer-body']}>
              <RobotImage />
              <div className={styles['body-info-wrapper']}>
                <h2>{t('overview-verify')}</h2>
                <div className={styles['body-info']}>
                  <span>{t('overview-verify-alt')}</span>
                  <Button
                    className="overview-banner"
                    type="button"
                    onClick={() => navigate(`/${customerId}/application`)}
                  >
                    {t('overview-open-offer')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* {openReqDlg ? (
          <RequestDialog
            open={openReqDlg}
            onClose={() => setOpenReqDlg(false)}
          />
        ) : null} */}
      </>
    );
  }
};

export default Overview;
