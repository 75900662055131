import Box from '@mui/material/Box';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import { StyledPin } from '../registration_components/StyledPin';
import axios from '../../../../utils/axios';
import { FormWrapper } from '../../../Common/FormWrapper';
import { RegistrationSteps } from '../../../../constants/registrationSteps';
import { useLoading } from '../../../../context/loading-context';
import Loading from '../../../UI/Loading';
import { TUserRegisterData } from '../registrationSchema';
import { addSnackbar } from '../../../../store/slices/snackbarsSlice';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../selectors/selectors';
import {
  calculateRemainingPinValidationTime,
  displayRemainingTime,
} from '../../../../utils/date';
import { APIService } from '../../../../services/api';
import { AxiosError } from 'axios';
import { HttpStatusCode } from '../../../../constants/httpStatusCode';

interface IPinProps {
  title: string;
  moveToStep: (step: RegistrationSteps) => void;
  updateRegistrationData: (fields: Partial<TUserRegisterData>) => void;
  registrationData: TUserRegisterData;
}

interface IVerificationData {
  id: string;
  address: string;
  validTo: string;
}

export const Pin: FC<IPinProps> = ({
  title,
  moveToStep,
  updateRegistrationData,
  registrationData,
}) => {
  const [verificationData, setVerificationData] = useState<IVerificationData>({
    address: '',
    id: '',
    validTo: '',
  });
  const [leadId, setLeadId] = useState('');
  const { currentUser } = useAppSelector((state) => state.currentUser);

  const { pinDigits, phone } = registrationData;
  const timestamp = verificationData.validTo;

  const [remainingSeconds, setRemainingSeconds] = useState(
    calculateRemainingPinValidationTime(timestamp)
  );

  const { loading, setLoading } = useLoading();
  const [pinError, setPinError] = useState(false);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const hasTimeExpired = remainingSeconds <= 0;

  useEffect(() => {
    try {
      const verificationData = localStorage.getItem('verification_data');
      const unparsedLeadId = localStorage.getItem('lead_id');
      if (verificationData) {
        setVerificationData(JSON.parse(verificationData));
      }
      if (unparsedLeadId) {
        setLeadId(JSON.parse(unparsedLeadId));
      }
    } catch {
      dispatch(
        addSnackbar({
          variant: 'error',
          message: t('registration.errors.general-registration-error'),
        })
      );
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newRemainingSeconds =
        calculateRemainingPinValidationTime(timestamp);
      setRemainingSeconds(newRemainingSeconds);

      if (newRemainingSeconds <= 0) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timestamp]);

  const handlePinChange = (index: number, value: string) => {
    const newValue = (value = value.slice(0, 1));
    const newPinDigits = [...pinDigits];
    newPinDigits[index] = value;

    updateRegistrationData({ pinDigits: newPinDigits });

    if (index < 5 && newValue !== '') {
      const nextInput = document.getElementById(`pin-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const onSubmit = async () => {
    const data = {
      otp: pinDigits.join(''),
    };
    const isAnyPinNotFilled = pinDigits.some((digit) => digit === '');
    setPinError(isAnyPinNotFilled);

    if (!isAnyPinNotFilled) {
      try {
        setLoading(true);
        await axios.post(
          `parties/address-verifications/${verificationData.id}/response`,
          data
        );
        localStorage.removeItem('verification_data');
        localStorage.removeItem('lead_id');
        moveToStep(RegistrationSteps.FinalStep);
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            setPinError(t('registration.errors.invalid-otp-error'));
            updateRegistrationData({ pinDigits: ['', '', '', '', '', ''] });
          }
        } else {
          setPinError(t('registration.errors.general-registration-error'));
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleArrowNavigation = (
    index: number,
    key: string,
    pinDigits: string[],
    updateFocus: (index: number) => void
  ) => {
    const lastIndex = pinDigits.length - 1;

    switch (key) {
      case 'ArrowLeft':
        index > 0 && updateFocus(index - 1);
        break;
      case 'ArrowRight':
        index < lastIndex && updateFocus(index + 1);
        break;
      default:
        break;
    }
  };
  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    const { key } = event;

    if (key === 'ArrowLeft' || key === 'ArrowRight') {
      handleArrowNavigation(index, key, pinDigits, (nextIndex) => {
        const inputId = `pin-input-${nextIndex}`;
        const inputElement = document.getElementById(inputId);
        inputElement && inputElement.focus();
      });
    } else if (key === 'Backspace') {
      event.preventDefault();

      const newPinDigits = [...pinDigits];
      newPinDigits[index] = '';

      updateRegistrationData({ pinDigits: newPinDigits });

      if (index > 0) {
        const prevIndex = index - 1;
        const prevInputId = `pin-input-${prevIndex}`;
        const prevInputElement = document.getElementById(prevInputId);
        prevInputElement && prevInputElement.focus();
      }
    }
  };

  const resendSMS = async (e: MouseEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault();
      setLoading(true);
      const verification: IVerificationData = await APIService.verifyPhone(
        phone,
        leadId,
        currentUser
      );
      setVerificationData(verification);
      setLoading(false);
    } catch {
      setPinError(t('registration.errors.general-registration-error'));
    }
  };

  const remainingVerificationTime = !hasTimeExpired ? (
    <Typography>
      {t('registration.pin-expire-message')}{' '}
      {displayRemainingTime(remainingSeconds)}
    </Typography>
  ) : null;

  const footer = (
    <Box mt="1rem">
      <Typography>
        {t('registration.registration-pin-confirm-subheader')}{' '}
      </Typography>
      {hasTimeExpired ? (
        <Box>
          <Typography>
            {t('registration.no-sms-pin')}{' '}
            <Link
              component="button"
              variant="body2"
              color="inherit"
              sx={{ '&:hover': { background: 'none' } }}
              onClick={resendSMS}
            >
              {t('registration.resend-sms-pin')}
            </Link>
            .
          </Typography>
        </Box>
      ) : (
        remainingVerificationTime
      )}
    </Box>
  );

  return (
    <FormWrapper
      title={title}
      footer={footer}
      currentStep={6}
      onSubmit={onSubmit}
    >
      <Loading isLoading={loading} />
      <Box display="flex" gap={1} sx={{ width: '350px' }}>
        {pinDigits.map((digit, index) => (
          <StyledPin
            key={index}
            value={digit}
            id={`pin-input-${index}`}
            onChange={(e) => handlePinChange(index, e.target.value)}
            error={pinError}
            type="number"
            autoFocus={index === 0}
            onKeyDown={(e) => handleKeyDown(index, e)}
          />
        ))}
      </Box>
      <Typography
        color="error"
        sx={{
          display: 'block',
          width: '310px',
          marginTop: '4px',
          fonWeight: 400,
          fontSize: '0.75rem',
          fontFamily: 'Inter,Roboto,Helvetica,Arial,sans-serif',
        }}
      >
        {pinError}
      </Typography>
    </FormWrapper>
  );
};
