import { FC, useState, FormEvent } from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';

import { FormWrapper } from '../../../Common/FormWrapper';
import { TUserRegisterData } from '../registrationSchema';
import { RegistrationSteps } from '../../../../constants/registrationSteps';
import { Typography } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface IWebsitesProps {
  registrationData: TUserRegisterData;
  updateRegistrationData: (fields: Partial<TUserRegisterData>) => void;
  moveToStep: (step: RegistrationSteps) => void;
}

const urlRegex =
  /^((https?|ftp|smtp):\/\/)?(www\.)?[a-zA-Z0-9_-]+(\.[a-zA-Z]{2,})+((\/[a-zA-Z0-9_\-#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?)?\/?$/;

export const Websites: FC<IWebsitesProps> = ({
  updateRegistrationData,
  registrationData,
  moveToStep,
}) => {
  const [websitesErrors, setWebsitesErrors] = useState<Array<string>>([]);
  const [hasNoWeb, setHasNoWeb] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleHasWebChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setHasNoWeb(isChecked);
    if (isChecked) {
      updateRegistrationData({ websites: [''] });
      setWebsitesErrors([]);
    }
  };

  const addNewWeb = (event: FormEvent) => {
    event.preventDefault();

    if (!isFormValid()) {
      return;
    }

    updateRegistrationData({
      websites: [...registrationData.websites, ''],
    });

    setWebsitesErrors([]);
  };

  const handleWebsiteChange = (index: number, value: string) => {
    const updatedWebsites = [...registrationData.websites];
    updatedWebsites[index] = value;
    updateRegistrationData({ websites: updatedWebsites });
  };

  const handleWebsiteRemove = (indexToRemove: number) => {
    updateRegistrationData({
      websites: registrationData.websites.filter(
        (_, index) => index !== indexToRemove
      ),
    });
  };

  const isFormValid = (): boolean => {
    if (hasNoWeb) {
      setWebsitesErrors([]);
      return true;
    }

    // Create an object to keep track of website frequencies
    const websiteFrequency: Record<string, number> = {};

    const errors = registrationData.websites.map((website) => {
      if (!website || website.trim() === '') {
        return t('registration.validation.website-required');
      } else if (!urlRegex.test(website)) {
        return t('registration.validation.website-incorrect');
      } else {
        const lowerCaseWebsite = website.toLowerCase();
        websiteFrequency[lowerCaseWebsite] =
          (websiteFrequency[lowerCaseWebsite] || 0) + 1;
        return '';
      }
    });

    registrationData.websites.forEach((website, index) => {
      const lowerCaseWebsite = website.toLowerCase();
      if (websiteFrequency[lowerCaseWebsite] > 1) {
        errors[index] = t('registration.validation.website-duplicate');
      }
    });

    setWebsitesErrors(errors);

    return errors.every((error) => error === '');
  };

  const onSubmit = () => {
    if (isFormValid()) {
      moveToStep(RegistrationSteps.ICO);
    }
  };

  return (
    <FormWrapper
      onSubmit={onSubmit}
      title={t('registration.registration-websites-header')}
      footer={t('registration.registration-websites-footer')}
      currentStep={2}
    >
      {registrationData.websites.map((website, index) => (
        <Box display="flex" justifyContent="flex-start" gap={2} key={index}>
          <TextField
            autoFocus={index === registrationData.websites.length - 1}
            type="text"
            size="small"
            placeholder={t('registration.web-placeholder')}
            sx={{ mr: 2, width: '250px', mb: 1 }}
            value={website}
            onChange={(e) => handleWebsiteChange(index, e.target.value)}
            disabled={hasNoWeb}
            error={Boolean(websitesErrors[index])}
            helperText={websitesErrors[index]}
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
            }}
          >
            {index === 0 && (
              <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                <IconButton onClick={addNewWeb} color="primary" size="small">
                  <AddIcon />
                </IconButton>
                <Typography variant="h6" fontWeight={400} sx={{ mt: 0.5 }}>
                  {t('common-add-another')}
                </Typography>
              </Box>
            )}

            {index > 0 && (
              <IconButton
                onClick={() => handleWebsiteRemove(index)}
                color="info"
                size="small"
              >
                <DeleteOutlineIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      ))}

      <Box
        display="flex"
        justifyContent="flex-start"
        gap={2}
        flexDirection="column"
        mt={3}
      >
        <FormControlLabel
          control={<Checkbox value={hasNoWeb} onChange={handleHasWebChanged} />}
          label={t('registration.registration-no-web')}
        />
      </Box>
    </FormWrapper>
  );
};
