import TextField from '@mui/material/TextField';
import { FC, MutableRefObject, useEffect, useState } from 'react';

import { FormWrapper } from '../../../Common/FormWrapper';
import { TUserRegisterData } from '../registrationSchema';
import { useTranslation } from 'react-i18next';
import { RegistrationSteps } from '../../../../constants/registrationSteps';
import axios from '../../../../utils/axios';
import Loading from '../../../UI/Loading';
import { HttpStatusCode } from '../../../../constants/httpStatusCode';
import { AxiosError } from 'axios';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../selectors/selectors';
import { useLoading } from '../../../../context/loading-context';
import { addSnackbar } from '../../../../store/slices/snackbarsSlice';
import { setCurrentUserAction } from '../../../../store/slices/currentUserSlice';
import { ExternalUserDto, RegisterInfo } from '../../../../types/api';
import Stack from '@mui/material/Stack';
import { CustomerRegistrationCountries } from '../../../../enums/customerRegistrationCountries';
import { InputAdornment, MenuItem } from '@mui/material';
import { parseApiError } from '../../../../utils/utils';
import { InfoOrganization } from '../registration_components/InfoOrganization';

interface IICOProps {
  registrationData: TUserRegisterData;
  updateRegistrationData: (fields: Partial<TUserRegisterData>) => void;
  title: string;
  moveToStep: (step: RegistrationSteps) => void;
  infoOrganizationRef: MutableRefObject<RegisterInfo | null>;
}

const countries = [
  { code: CustomerRegistrationCountries.CZ, label: 'countries.czech-republic' },
  { code: CustomerRegistrationCountries.SK, label: 'countries.slovakia' },
  { code: CustomerRegistrationCountries.NL, label: 'countries.netherlands' },
  { code: CustomerRegistrationCountries.DE, label: 'countries.germany' },
  { code: CustomerRegistrationCountries.GB, label: 'countries.great-britian' },
  { code: CustomerRegistrationCountries.FR, label: 'countries.france' },
  { code: CustomerRegistrationCountries.BE, label: 'countries.belgium' },
];

export const ICO: FC<IICOProps> = ({
  registrationData,
  updateRegistrationData,
  title,
  moveToStep,
  infoOrganizationRef,
}) => {
  const { t } = useTranslation();
  const { loading, setLoading } = useLoading();
  const [icoErrors, setIcoErrors] = useState<Partial<TUserRegisterData>>({});
  const [leadId, setLeadId] = useState('');
  const [isFormValidated, setIsFormValidated] = useState(false);

  const dispatch = useAppDispatch();
  const {
    currentUser: { currentUser },
    language,
  } = useAppSelector((state) => state);
  const currentCustomer = currentUser?.customers?.[0];

  useEffect(() => {
    try {
      const unparsedLeadId = localStorage.getItem('lead_id');
      if (unparsedLeadId) {
        setLeadId(JSON.parse(unparsedLeadId));
      }
    } catch (error) {
      dispatch(
        addSnackbar({
          variant: 'error',
          message: t('registration.errors.general-registration-error'),
        })
      );
    }
  }, []);

  const isFormValid = (): boolean => {
    let errors: Partial<TUserRegisterData> = {};
    let isValid = true;
    if (registrationData.ico === '') {
      errors.ico = t('registration.validation.ico-required');
      isValid = false;
    }
    if (registrationData.country === '') {
      errors.country = t('registration.validation.business-country-required');
      isValid = false;
    }
    setIcoErrors(errors);

    return isValid;
  };

  const validateIco = async () => {
    if (isFormValid() && !isFormValidated) {
      const supportedIcoCountries = [
        CustomerRegistrationCountries.CZ,
        CustomerRegistrationCountries.SK,
        CustomerRegistrationCountries.NL,
      ];

      if (
        !supportedIcoCountries.includes(
          registrationData.country as CustomerRegistrationCountries
        )
      ) {
        setLoading(true);

        // Simulate an API request using a Promise
        new Promise((_, reject) => {
          setTimeout(() => {
            setLoading(false);
            reject(new Error(t('registration.errors.ico-not-found-error')));
          }, 1000);
        }).catch((error) => {
          setIcoErrors({ ico: error.message });
        });
        return;
      }
      if (
        currentCustomer?.regNum === registrationData.ico &&
        currentCustomer?.country === registrationData.country
      ) {
        moveToStep(RegistrationSteps.ICOData);
      } else {
        try {
          setLoading(true);

          const { data } = await axios.post<RegisterInfo>(
            '/parties/validate-regnum',
            {
              regNum: registrationData.ico,
              country: registrationData.country,
            }
          );
          infoOrganizationRef.current = data;
          setIsFormValidated(true);
          setLoading(false);
        } catch (error) {
          const { message } = parseApiError(error);
          const errorMessage = t(`registration.validation.${message}`);
          setIcoErrors((prevState) => ({ ...prevState, ico: errorMessage }));
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const onSubmit = async () => {
    if (isFormValid() && isFormValidated) {
      if (
        currentCustomer?.regNum === registrationData.ico &&
        currentCustomer?.country === registrationData.country
      ) {
        moveToStep(RegistrationSteps.ICOData);
      } else {
        try {
          setLoading(true);

          await axios.post('/customers', {
            regNum: registrationData.ico,
            segment: registrationData.business,
            urls: registrationData.websites,
            country: registrationData.country,
            leadId,
          });

          localStorage.removeItem('lead_id');

          const user = await axios.get('users/current');
          dispatch(setCurrentUserAction(user.data as ExternalUserDto));
          setLoading(false);
          moveToStep(RegistrationSteps.ICOData);
        } catch (error) {
          if (error instanceof AxiosError) {
            if (
              error.response?.status === HttpStatusCode.UNPROCESSABLE_ENTITY
            ) {
              setIcoErrors((prevState) => {
                return {
                  ...prevState,
                  ico: t('registration.errors.ico-fetch-error'),
                };
              });
            }
          }
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const sortedCountries = countries.sort((a, b) =>
    t(a.label).localeCompare(t(b.label), language)
  );

  return (
    <FormWrapper
      title={title}
      footer={
        <InfoOrganization
          infoOrganization={infoOrganizationRef.current}
          onSubmit={onSubmit}
        />
      }
      currentStep={3}
      onSubmit={validateIco}
      buttonText={t('search')}
    >
      <Stack spacing={4} direction="column">
        <TextField
          select
          value={registrationData.country}
          size="small"
          label={t('registration.registration-ico-country-select')}
          sx={{
            width: '100%',
            maxWidth: '300px',
            minWidth: '200px',
          }}
          error={!!icoErrors.country}
          helperText={icoErrors.country}
          onChange={(e) => {
            updateRegistrationData({
              country: e.target.value as CustomerRegistrationCountries,
            });
          }}
          InputProps={{
            startAdornment: registrationData.country ? (
              <InputAdornment position="start">
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${registrationData.country.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${registrationData.country.toLowerCase()}.png 2x`}
                  alt="Country flag"
                  style={{ width: '25px', height: '18px' }}
                />
              </InputAdornment>
            ) : null,
          }}
        >
          {sortedCountries.map((country) => (
            <MenuItem key={country.code} value={country.code}>
              {t(country.label)}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          type="number"
          value={registrationData.ico}
          label={t('registration.reistration-ico-label')}
          onChange={(e) => {
            if (e.target.value.length <= 8) {
              updateRegistrationData({ ico: e.target.value });
              setIsFormValidated(false);
            }
          }}
          error={!!icoErrors.ico}
          helperText={icoErrors.ico}
          size="small"
          sx={{
            width: '100%',
            maxWidth: '300px',
            minWidth: '200px',
          }}
        />
      </Stack>

      <Loading isLoading={loading} />
    </FormWrapper>
  );
};
