import { Typography } from "@mui/material";
import { FormWrapper } from "../../../Common/FormWrapper";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ProlongationHelpFinal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSubmit = () => {
    navigate(`/`);
  };

  return (
    <FormWrapper onSubmit={onSubmit} title="" currentStep={2} isLastStep>
      <Typography>
        {t("support.success")}
        <br />
        <br />
        {t("support.success-contact")}
      </Typography>
    </FormWrapper>
  );
};
