import { useTranslation } from 'react-i18next';
import styles from './QrCode.module.scss';
import { FC } from 'react';
import { IsoCurrencyCode } from '../../types/api';
import { Box } from '@mui/material';

interface IpropsProps {
  qrCode: string;
  currency: string;
}

const QrCode: FC<IpropsProps> = ({ qrCode, currency }) => {
  const { t } = useTranslation();

  return qrCode ? (
    <div className={styles['qr-code']}>
      <Box sx={{ marginLeft: currency === IsoCurrencyCode.CZK ? '1rem' : 0 }}>
        <img src={`data:image/jpeg;base64,${qrCode}`} alt="qrcode" />

        {currency === IsoCurrencyCode.CZK ? (
          <div className={styles['qr-info']}>
            <h3>{t('payment-instruction-qr')}</h3>
          </div>
        ) : null}
      </Box>
    </div>
  ) : null;
};

export default QrCode;
