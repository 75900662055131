import { FC } from "react";
import { FormWrapper } from "../../../Common/FormWrapper";
import {
  Box,
  Table,
  TableContainer,
  Typography,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import QrCode from "../../../Sections/QrCode";
import { LoanDto, ProlongationPreviewDto } from "../../../../types/api";
import { formatDate, formatMoney } from "../../../../utils/format";
import { useTranslation } from "react-i18next";

interface IProlongationRequestInformationProps {
  next: () => void;
  loan: LoanDto;
  prolongationPreview: ProlongationPreviewDto | null;
}

export const ProlongationRequestInformation: FC<
  IProlongationRequestInformationProps
> = ({ next, loan, prolongationPreview }) => {
  const { t } = useTranslation();

  const onSubmit = () => {
    next && next();
  };

  const {
    externalLoanRef,
    prolongationFeeTotal,
    paymentQrCode,
  } = prolongationPreview ?? {};

  const { paymentInstruction } = loan;

  return (
    <FormWrapper
      onSubmit={onSubmit}
      currentStep={2}
      buttonText={t("prolongation-form-payment-acknowledgement")}
      title={t('prolongation-form-payment-title')}
    >
      <Typography fontWeight={400}>
        {t("prolongation-form-payment-activation-1")}{" "}
        <Typography component="span" color="primary" fontWeight="bold">
          {t("prolongation-form-payment-activation-2")}
        </Typography>{" "}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "flex-start",
          gap: 2,
          padding: 2,
          border: "1px solid #ccc",
          borderRadius: "8px",
          margin: "1rem auto",
          flexDirection: { xs: "column", sm: "row" }, // Stack column on small screens
        }}
      >
        {/* Left Side Table */}
        <Box sx={{ flex: 2 }}>
          <TableContainer component={Paper} elevation={0}>
            <Table aria-label="payment details">
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      {t("prolongation-form-payment-bank-account")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="secondary"
                    >
                      {paymentInstruction?.bban ?? paymentInstruction?.iban}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      {t("prolongation-form-payment-bank-ref")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="secondary"
                    >
                      {externalLoanRef ?? ""}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      {t("prolongation-form-payment-total-due")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="secondary"
                    >
                      {formatMoney(
                        prolongationFeeTotal,
                        paymentInstruction?.currency
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      {t("prolongation-form-payment-due-date")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="secondary"
                    >
                      {formatDate(new Date().toISOString())}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Right Side QR Code */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <QrCode
            currency={paymentInstruction?.currency ?? ""}
            qrCode={paymentQrCode ?? ""}
          />
        </Box>
      </Box>
      <Typography fontSize="14px">
        {t("prolongation-form-payment-warning")}
      </Typography>
    </FormWrapper>
  );
};
