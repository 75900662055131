import { FormWrapper } from "../../../Common/FormWrapper";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const ProlongationRequestFinal = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const onSubmit = () => {
    navigate("/");
  };

  return (
    <FormWrapper
      onSubmit={onSubmit}
      currentStep={3}
      isLastStep
      title=""
      buttonText={t("prolongation-form-return-to-application")}
    >
      <Typography>{t("prolongation-form-success-processing")}</Typography>
      <br />
      <br />
      <Typography>
        {t("prolongation-form-success-processing-payment-1")}{" "}
        <Typography fontWeight="bold" component="span">
          {t("prolongation-form-success-processing-payment-2")}
        </Typography>{" "}
        {t("prolongation-form-success-processing-payment-3")}
      </Typography>
    </FormWrapper>
  );
};
