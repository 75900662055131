import { useEffect, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import PaymentTable from '../Sections/PaymentTable';
import CurrentFinancing from '../Sections/CurrentFinancing';
import NextPayment from '../Sections/NextPayment';
import PaymentInfo from '../Sections/PaymentInfo';
import QrCode from '../Sections/QrCode';
import HistoryTable from '../Sections/HistoryTable';

import styles from './MyFinancing.module.scss';
import {
  IsoCurrencyCode,
  LoanDto,
  LoansOverviewDtoPreviousLoanDto,
} from '../../types/api';

interface IMyFinancingProps {
  loanActive: any; //LoanDto
  loanHistory?: Array<LoansOverviewDtoPreviousLoanDto> | null;
  selectCustomerHandler: () => void;
  customerId: string;
}

const MyFinancing: FC<IMyFinancingProps> = ({
  loanActive,
  loanHistory,
  customerId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loanActive && loanHistory && loanHistory.length) {
      navigate(`/${customerId}/my-financing/${loanHistory[0].id}`);
    }
  }, [loanActive, loanHistory, customerId]);

  return (
    <div className={styles['my-financing-wrapper']}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {loanActive && loanActive.length > 0
          ? loanActive.map((loan: LoanDto, index: number) => {
              const { paymentInstruction } = loan || {};

              const instalmentsPlanned = loan
                ? loan?.instalments?.filter((i) =>
                    ['PLANNED', 'OVERDUE'].includes(i.state ?? '')
                  )
                : [];
              return (
                <div className={styles['payment-wrapper']} key={loan.id}>
                  <h2>
                    {t('financing-active-financing', { index: index + 1 })}
                  </h2>

                  <PaymentTable loan={loan} />
                  <CurrentFinancing
                    className="center"
                    customerId={customerId}
                    loan={loan}
                  />

                  {instalmentsPlanned?.length && (
                    <NextPayment
                      planned={instalmentsPlanned[0]}
                      currency={loan.currency as IsoCurrencyCode}
                    />
                  )}

                  {paymentInstruction ? (
                    <div className={styles['financing-info-wrapper']}>
                      <PaymentInfo
                        paymentInstruction={paymentInstruction}
                        currency={loan.currency as IsoCurrencyCode}
                      />
                      <QrCode
                        currency={loan?.paymentInstruction?.currency ?? ''}
                        qrCode={loan?.paymentInstruction?.qrCodeString ?? ''}
                      />
                    </div>
                  ) : null}
                  {index < loanActive.length - 1 ? (
                    <Divider sx={{ mb: 2, mt: 2, borderBottomWidth: '2px' }} />
                  ) : null}
                </div>
              );
            })
          : null}
      </Box>

      {loanHistory && loanHistory.length > 0 && (
        <div className={styles['history-wrapper']}>
          <h2>{t('financing-history')}</h2>
          <HistoryTable history={loanHistory} customerId={customerId} />
        </div>
      )}
    </div>
  );
};

export default MyFinancing;
