import { useState, useEffect, FC } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PaymentTable from '../Sections/PaymentTable';
import CurrentFinancing from '../Sections/CurrentFinancing';
import PaymentInfo from '../Sections/PaymentInfo';
import QrCode from '../Sections/QrCode';
import HistoryTable from '../Sections/HistoryTable';

import { useLoading } from '../../context/loading-context';
import { useErrorHandler } from '../../context/error-handler-context';
import axios from '../../utils/axios';

import styles from './FinancingDetail.module.scss';
import {
  IsoCurrencyCode,
  LoanDto,
  LoansOverviewDtoPreviousLoanDto,
} from '../../types/api';
import { Box } from '@mui/material';

interface IFinancingDetailProps {
  history?: Array<LoansOverviewDtoPreviousLoanDto> | null;
  customerId: string;
}

const FinancingDetail: FC<IFinancingDetailProps> = ({
  history,
  customerId,
}) => {
  const { t } = useTranslation();
  const [loan, setLoan] = useState<LoanDto | null>(null);

  const { setLoading } = useLoading();
  const { setError } = useErrorHandler();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/loans/${id}`)
      .then((res) => {
        setLoading(false);
        setLoan(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }, [id, setError, setLoading]);

  let content = null;
  if (loan) {
    const { paymentInstruction } = loan;

    content = (
      <div
        className={`${styles['financing-detail-wrapper']} ${
          history?.length === 0 && styles['no-history']
        }`}
      >
        <Link to={`/${customerId}/my-financing`}>{`<< ${t(
          'financing-history-back'
        )}`}</Link>

        <div className={styles['detail-wrapper']}>
          <div className={styles['payment-wrapper']}>
            <h2>{t('common-details')}</h2>

            <PaymentTable loan={loan} />

            <CurrentFinancing
              className="detail"
              showOverdueInfo
              customerId={customerId}
              loan={loan}
            />

            {paymentInstruction && (
              <Box sx={{ display: 'flex', gap: '1.6rem' }}>
                <Box
                  sx={{
                    width: '160px',
                    '@media (max-width: 700px)': {
                      display: 'none',
                    },
                  }}
                ></Box>
                <div
                  className={`${styles['financing-info-wrapper']} ${styles.detail}`}
                >
                  <PaymentInfo
                    paymentInstruction={paymentInstruction}
                    currency={loan.currency as IsoCurrencyCode}
                  />
                  <QrCode
                    currency={loan?.paymentInstruction?.currency ?? ''}
                    qrCode={loan?.paymentInstruction?.qrCodeString ?? ''}
                  />
                </div>
              </Box>
            )}
          </div>

          {history && history.length > 0 && (
            <div className={styles['history-wrapper']}>
              <h2>{t('financing-history')}</h2>
              <HistoryTable history={history} customerId={customerId} />
            </div>
          )}
        </div>
      </div>
    );
  }

  return <div className="content-wrapper">{content}</div>;
};

export default FinancingDetail;
