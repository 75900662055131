import { FC } from 'react';

import { FormWrapper } from '../../../Common/FormWrapper';

interface IFinalStepProps {
  title: string;
  buttonText: string;
}

export const FInalStep: FC<IFinalStepProps> = ({ title, buttonText }) => {
  const handleSubmit = async () => {
    window.location.reload();
  };
  return (
    <FormWrapper
      title={title}
      isLastStep
      buttonText={buttonText}
      currentStep={7}
      onSubmit={handleSubmit}
    >
      {null}
    </FormWrapper>
  );
};
